import { MediaType } from '@/prisma/utils/media-type';
import {
	Box, Heading, List, Text, ListItem, OrderedList
} from '@chakra-ui/react';
import React from 'react';

type MediaTypeListProps = {
	mediaTypes: MediaType[],
	children?: ({ mediaType }: { mediaType: MediaType }) => JSX.Element | undefined;
}

const MediaTypeList: React.FC<MediaTypeListProps> = ({ mediaTypes, children }) => {
	const list = mediaTypes.map(item => {
		return <ListItem
			key={item.id}
			padding="4"
			bgColor="green.50"
			borderRadius="lg"
		>
			<Box>
				<Heading>{item.name}</Heading>
				<Text>{item.description}</Text>
				{children({ mediaType: item })}
			</Box>
		</ListItem>;
	});

	return (
		<List
			spacing="4"
		>
			{list}
		</List>
	);
};

MediaTypeList.defaultProps = {
	children: () => undefined,
};

export default React.memo(MediaTypeList);
