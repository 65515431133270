import {
	Box, Flex, Heading, Text
} from '@chakra-ui/layout';
import { AspectRatio } from '@chakra-ui/react';
import NextImage from 'next/image';
import { Media } from '../../prisma/utils/media';

type MediaThumbnailProps = {
	media: any;
}

const MediaThumbnail: React.FC<MediaThumbnailProps> = ({ media }) => {
	const { sourceUuid } = media;
	// const sourceUuid = 'r7xsYgTeM2Q';
	// https://www.youtube.com/oembed?url=https://www.youtube.com/watch?v=UEEsdXn8oG8&format=json
	const posterSrc = `http://img.youtube.com/vi/${sourceUuid}/0.jpg`;
	const ratio = 16/9;

	return (
		<Box>
			{/* <Heading size="md">{media.sourceTitle}</Heading> */}
			{/* <Flex justifyContent="space-between">
				<Text>{media.sourceAuthor}</Text>
			</Flex> */}
			<Poster
				src={posterSrc}
				ratio={ratio}
			/>
		</Box>
	);
};

const Poster = ({ src, ratio }) => <AspectRatio
	ratio={ratio}
	backgroundColor="gray.100"
>
	<NextImage
		src={src}
		layout="fill"
		objectFit="cover"
	></NextImage>
</AspectRatio>;

export default MediaThumbnail;
