import { Box, SimpleGrid } from '@chakra-ui/layout';
import { Playlist } from '../../prisma/utils/playlist';
import MediaThumbnail from '../media/thumbnail';
import PlaylistCard from './card';

type PlaylistGridProps = {
	playlists: Playlist[];// | PlaylistWithItems[];
	controls?: ({ playlist }: { playlist: Playlist }) => JSX.Element | undefined;
	children?: ({ playlist }: { playlist: Playlist }) => JSX.Element | undefined;
}

const PlaylistGrid: React.FC<PlaylistGridProps> = ({
	playlists = [], controls, children,
}) => {
	const items = playlists.map(item => {
		return <Box
			key={item.id}
			as="li"
			padding="4"
			bgColor="blue.50"
			borderRadius="lg"
		>
			<Box>
				<PlaylistCard
					playlist={item}
				/>
				{controls({ playlist: item })}
				{children({ playlist: item })}
			</Box>
		</Box>;
	});

	return (
		<SimpleGrid
			as="ul"
			listStyleType="none"
			spacing="4"
			columns={[1, null, 2, 3, 4]}
		>
			{items}
		</SimpleGrid>
	);
};

PlaylistGrid.defaultProps = {
	children: ({ playlist }) => undefined,
	controls: ({ playlist }) => undefined,
};

export const PlaylistItemsGrid = ({ items  }) => {
	return <SimpleGrid
		columns={[5]}
		spacing={[1]}
	>
		{items.map(item => <MediaThumbnail
			key={item.id}
			media={item.media}
		/>)}
	</SimpleGrid>;
};

export default PlaylistGrid;
