import {
	Text, Box, Link, Tag, Heading 
} from '@chakra-ui/react';

import { Playlist } from '../../prisma/utils/playlist';
import TagCloud from '../tag/cloud';

type PlaylistCardProps = {
	playlist: Playlist;
}

const PlaylistCard: React.FC<PlaylistCardProps> = ({ playlist }) => {
	return (
		<Box>
			<Tag colorScheme="green">
				<Link href={`/media-types/${playlist.type.slug}`}>
					{playlist.type.name}
				</Link>
			</Tag>

			<Heading>
				<Link href={`/playlists/${playlist.id}/${playlist.slug}`}>
					{playlist.name}
				</Link>
			</Heading>
			
			<Text>{playlist.level}</Text>
			<Text>{playlist.description}</Text>
			
			<Text>Categories</Text>
			<TagCloud
				tags={playlist.categories}
				hrefPrefix="/playlists/filter/categories"
				colorScheme="blue"
			/>

			<Text>Tags</Text>
			<TagCloud
				tags={playlist.tags}
				hrefPrefix="/playlists/filter/tags"
			/>
		</Box>
	);
};

export default PlaylistCard;
