import MediaTypeList from '@/components/media-type/list';
import UserCard from '@/components/user/card';
import { useRecentPlaylists } from '@/hooks/api';
import { fetchPopularMediaTypes, MediaTypeWithPlaylists } from '@/prisma/utils/media-type';
import {
	Divider, Heading, Text
} from '@chakra-ui/layout';
import { GetStaticProps } from 'next';
import { QueryClient } from 'react-query';
import { dehydrate } from 'react-query/hydration';
import LandingPage from '../components/layout/landing-page';
import MediaGrid from '../components/media/grid';
import PlaylistGrid, { PlaylistItemsGrid } from '../components/playlist/grid';
import { fetchRecentMedia, MediaWithRelations } from '../prisma/utils/media';
import {
	fetchRecentPlaylists, Playlist, PlaylistWithItems
} from '../prisma/utils/playlist';
import { fetchRecentUsers } from '../prisma/utils/user';

type HomePageProps = {
	mediaTypes: MediaTypeWithPlaylists[];
	recentMedia: MediaWithRelations[];
	// recentPlaylists: any[];
}

const HomePage: React.FC<HomePageProps> = (props: HomePageProps) => {
	const { mediaTypes, recentMedia } = props;
	const {
		isLoading,
		data: recentPlaylists,
	} = useRecentPlaylists();

	if (isLoading) {
		return <h1>Loading...</h1>;
	}

	return (
		<LandingPage
			title="Yoga!"
			description="Best Yoga video's online!"
		>
			<Heading size="lg">Types of yoga</Heading>
			<MediaTypeList mediaTypes={mediaTypes}>{
				({ mediaType }: { mediaType: MediaTypeWithPlaylists }) => <PlaylistGrid playlists={mediaType.playlists}>{
					({ playlist }: { playlist: Playlist }) => <Text>{playlist._count.interactions} interactions</Text>
				}</PlaylistGrid>
			}</MediaTypeList>
			<Heading size="lg">Recent playlists</Heading>
			<PlaylistGrid playlists={recentPlaylists}>{
				({ playlist }: { playlist: PlaylistWithItems }) => <>
					<PlaylistItemsGrid
						items={playlist.items || []}
					/>
					<Text>Curated by</Text>
					<UserCard user={playlist.owner} />

					<Text>{playlist._count.interactions} interactions</Text>
					<Text>{playlist._count.items} items</Text>
				</>
			}</PlaylistGrid>
			<Divider marginY="4" />
			<Heading size="lg">Recent videos</Heading>
			<MediaGrid media={recentMedia} />
		</LandingPage>
	);
};

export const getStaticProps: GetStaticProps = async ({ params }) => {
	const queryClient = new QueryClient();

	const mediaTypes = await fetchPopularMediaTypes();
	const recentUsers = await fetchRecentUsers();
	const recentMedia = await fetchRecentMedia();

	await queryClient.prefetchQuery(
		['playlists', { recent: true }], () => fetchRecentPlaylists()
	);

	return {
		revalidate: 5,
		props:{
			mediaTypes,
			recentUsers,
			recentMedia,
			dehydratedState: dehydrate(queryClient),
		},
	};
};

export default HomePage;
