import {
	Box, Code, SimpleGrid, Text
} from '@chakra-ui/react';
import React from 'react';
import { Media } from '../../prisma/utils/media';
import UserCard from '../user/card';
import MediaPreview from './preview';

type MediaGridProps = {
	media: Media[]
	controls?: ({ media }: { media: Media }) => JSX.Element | undefined;
}

const MediaGrid: React.FC<MediaGridProps> = ({ media, controls }) => {
	const list = media.map(item => {
		return <Box
			key={item.id}
			as="li"
			padding="4"
			bgColor="green.50"
			borderRadius="lg"
		>
			<Box>
				<MediaPreview
					media={item}
				/>
				<Text>Geplaatst door</Text>
				<UserCard user={item.owner} />
				<Code>{item.sourceUuid}</Code>
				{controls({ media: item})}
			</Box>
		</Box>;
	});

	return (
		<SimpleGrid
			as="ul"
			listStyleType="none"
			spacing="4"
			columns={[1, null, 2, 3, 4]}
		>
			{list}
		</SimpleGrid>
	);
};

MediaGrid.defaultProps = {
	controls: () => null,
};

export default React.memo(MediaGrid);
